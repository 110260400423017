<template>
  <div>
    menu subscribe
  </div>
</template>

<script>
export default {
data() {
    return {
        
    }
},
}
</script>

<style>

</style>